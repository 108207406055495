<template>
    <div class="course-ware-content">
        <div class="resource-classify">
            <div class="head-title">个人资源</div>
            <div class="back-btn">
                <el-button type="primary" size="medium" @click="uploadFile">上传资源</el-button>
                <el-button type="primary" size="medium" @click="goBack">返 回</el-button>
            </div>
        </div>
        <el-scrollbar class="course-ware-list">
            <div class="course-ware">
                <table class="dataTabble" rules=rows>
                    <thead class="table-head">
                    <tr class="table-head-title">
                        <th class="th-title1">标题</th>
                        <th class="th-title2">文件类型</th>
                        <th class="th-title3">上传时间</th>
                        <th class="th-title4">操作</th>
                    </tr>
                    </thead>
<!--                    <draggable :list="resourceData"-->
<!--                               :group="{name: 'resources', pull: 'clone', put: false }"-->
<!--                               element="tbody"-->
<!--                               :sort="false" :move="onMove" @end="onEnd" class="table-body">-->
                    <tbody class="table-body">
                        <tr v-for="(item,index) in resourceData" :key="index" class="table-trBox">
                            <td class="td-title">{{item.title}}</td>
                            <td>{{item.resource_file_type}}</td>
                            <td>{{item.create_time}}</td>
                            <td class="opt-box">
                                <div class="opt-innerBox">
                                    <el-button type="text" @click="downloadFile(item)">下载</el-button>
                                    <el-button type="text" @click="viewDetail(item)">查看详情</el-button>
                                    <el-button type="text" style="color: #F50000" @click="delAppBtn(item)">删除
                                    </el-button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
<!--                    </draggable>-->
                </table>
                <div v-if="resourceData.length === 0" class="no-data">
                    <span>暂无数据</span>
                </div>
            </div>
            <div class="page-box">
                <el-pagination
                        v-if="resourceData.length > 0"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        layout="prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </el-scrollbar>
        <div class="module-content" v-if="resourceData.length > 1">
            <el-scrollbar class="module" :native="false" :vertical="true">
                <div class="module-list">
                    <div :class="JSON.stringify(item)!=='{}'?'new-module-item':'module-item'"
                         v-for="(item,index) in module_list" :key="index">
                        <div class="item-cover1 course-ware" :class="item?item.class:''">
                            <div class="show-img" v-if="item && item.oldIndex || item.meterial_type_id">
                                <img v-if="item.oldIndex && item.meterial_type_id === undefined"
                                     :src="showCourseList[item.oldIndex - 1].src" alt=""
                                     class="img-box">
                                <img v-if="item.meterial_type_id" src="../../assets/images/peitao.png"
                                     alt=""
                                     class="img-box">
                            </div>
                            <div class="show-img" v-if="item.src">
                                <img :src="item.src" alt="" class="img-box">
                            </div>
                            <div v-show="JSON.stringify(item)!=='{}'" class="del-btn"
                                 @click="delModule(item,index)">x
                            </div>
                        </div>
                    </div>
                    <draggable :list="select_list" :group="{name: 'resources'}"
                               style="display: flex;flex-direction: row;opacity: 0;position: absolute">
                        <div class="module-item" style="opacity: 0" v-for="(item,index) in select_list"
                             :key="index">
                            <div class="item-cover course-ware" :class="item?item.class:''">
                                <i class="iconfont" v-html="item?item.icon:''"></i>
                            </div>
                            <el-tooltip v-show="JSON.stringify(item)!=='{}'" class="item" effect="light"
                                        :content="item.title" placement="bottom-end">
                                <div class="show-title"></div>
                            </el-tooltip>
                        </div>
                    </draggable>
                </div>
            </el-scrollbar>
            <div class="adhibition-btn" @click="useTempClick">
              <span>应用</span>
              <span>模板</span>
            </div>
            <div class="module-save-btn" @click="saveCourseCustom">
                <span>保存</span>
                <span>模板</span>
            </div>
            <div class="select-positioning">
              <el-popover popper-class="temp-popover" ref="popoverRef" :disabled="tempPoverShow"
                          placement="top-end" width="280" trigger="click">
                <div class="popover-item" @click="selectTempClick(item)" v-for="item in courseTempList">
                  <span>{{item.name}}</span>
                  <i class="iconfont" @click.stop="deleTemp(item.id)">&#xe60a;</i>
                </div>
                <div v-if="courseTempList.length === 0">暂无模板</div>
                <div class="hover-two" slot="reference">
                  <span>选择</span>
                  <span>模板</span>
                </div>
              </el-popover>
            </div>
            <el-dialog @close="closeTemplateBtn" custom-class="saveTemplate" title="保存模板"
                       :visible.sync="saveTemplateShow">
                <div class="template-box">
                    <div class="template-box-name">
                        <span>模板名称：</span>
                        <el-input v-model="templateName" placeholder="输入名称3-10字"
                                  style="width: 1%;flex: 1"></el-input>
                    </div>
                    <div class="template-box-btn">
                        <el-button @click="closeTemplateBtn">取消</el-button>
                        <el-button type="primary" @click="saveTemplateBtn">保存</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
        <ResourceDialog :dialogVisible="dialogVisible" :src="src" @closeDialog="closeDialog"></ResourceDialog>
        <el-dialog title="上传资料" :visible.sync="dialogUploadMaterial" width="650px"
                   class="carousel-dialog upload-resources-dialog" @close="resetUploadMaterialForm()"
                   :close-on-click-modal="false">
            <el-form :model="uploadMaterialForm" ref="uploadMaterialForm" :rules="uploadMaterialRules"
                     label-position="center" class="info-form">
                <el-form-item label="资料标题：" label-width="100px" prop="title">
                    <el-input v-model="uploadMaterialForm.title" autocomplete="off" placeholder="请输入标题"></el-input>
                </el-form-item>
<!--                <el-form-item label="资源类型：" label-width="100px" prop="select_resources_type">-->
<!--                    <el-select v-model="uploadMaterialForm.select_resources_type" placeholder="请选择类型" clearable-->
<!--                               class="select-course">-->
<!--                        <el-option v-for="item in typeList" :key="item.id" :label="item.name"-->
<!--                                   :value="item.id"></el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
                <el-form-item label="选择文件：" label-width="100px" prop="file" class="file">
                    <div class="file-name">
                        <div class="upload-cover">
                            <!--                            <i class="iconfont">&#xe659;</i>-->
                            <span>{{uploadMaterialForm.file ? '重新上传' : '上传'}}</span>
                        </div>
                        <input type="file" accept="*," @change="changeMaterial($event)" class="cover-input">
                        <div class="up-img-show" v-show="uploadMaterialForm.fileName">{{uploadMaterialForm.fileName}}
                        </div>
                    </div>
                    <span class="text" style="color: #F56C6C;" v-if="isUploadPDF">*请上传同文件pdf格式,以便浏览使用</span>
                    <div class="file-name" v-if="isPDFInput">
                        <div class="upload-cover">
                            <!--                            <i class="iconfont">&#xe659;</i>-->
                            <span>{{uploadMaterialForm.pdfFile ? '重新上传' : '上传PDF'}}</span>
                        </div>
                        <input type="file" accept="*," @change="changeMaterialPDF($event)" class="cover-input">
                        <div class="up-img-show" v-show="uploadMaterialForm.pdfName">{{uploadMaterialForm.pdfName}}
                        </div>
                    </div>
                    <span class="text">(文件支持格式为mp4、avi、pdf、word、excel、ppt、jpg、png、zip和rar)</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" @click="resetUploadMaterialForm">取 消</el-button>
                <el-button size="medium" type="primary" @click="saveUploadMaterial('uploadMaterialForm')"
                           :disabled="isUploadPDF">确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import tHttp from "../../utils/teacher_http";
    import draggable from "vuedraggable";
    import ResourceDialog from "../components/ResourceDialog";
    import _ from "underscore";

    export default {
        name: "teacherMatingDetailModule",
        props: ['select_list', 'module_list', 'searchInput', 'courseInfos', 'nodeIndex', 'chapterIndex',],
        components: {
            draggable,
            ResourceDialog
        },
        data() {
            return {
                currentPage: 1,
                pageSize: 6,
                total: "",
                //是否显示上传资料弹窗
                dialogUploadMaterial: false,
                //上传资料数据表单
                uploadMaterialForm: {
                    select_resources_type: '',
                    title: '',
                    resource_file_type: '',
                    file: '',
                    fileName: '',
                    file_path: '',
                    pdfFile: '',
                    pdfName: '',
                    file_preview_path: '',
                },
                uploadMaterialRules: {
                    select_resources_type: [
                        {required: true, message: '请选择资源类型', trigger: 'change'}
                    ],
                    file: [
                        {required: true, message: '请上传文件', trigger: 'change'}
                    ],
                    title: [
                        {required: true, message: '请输入标题', trigger: 'blur'},
                        {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'}
                    ],
                },
                //是否要上传PDF 文件
                isUploadPDF: false,
                isPDFInput: false,
                typeList: [],
                classId: null,
                resourceData: [],
                adminBtnShow: true,
                checkAll: false,
                isIndeterminate: true,
                selectAppList: [],
                applicationIdList: [],
                src: "",
                dialogVisible: false,
                saveTemplateShow: false,
                templateName: '',
                tempPoverShow: false,
                resourceList: [],
                courseContent: [],
                select_course_id: this.$route.query.course_id,
                papersList: [],
                projectsList: [],
                material_list: [],
                cKejian: [],
                cJiaocai: [],
                cWeike: [],
                cDonghua: [],
                cPeitao: [],
                cJiaoan: [],
                cZhishidian: [],
                cSucaiku: [],
                cAnliku: [],
                cPeiWeike: [],
                cXiangguanwenzhang: [],
                cPersonResource: [],
                testCenter: [],
                practiceCenter: [],
                showPeitao: [],
                course_content: [],
                selectTemplateList: [],
                courseTempList: [],
                showCourseList: [
                    {
                        class: 'courseware',
                        icon: '&#xe677;',
                        src: require('../../assets/images/courseware.png'),
                        title: '课件',
                        index: 1,
                        mtype: 0,
                    },
                    {
                        class: 'textbook',
                        icon: '&#xe687;',
                        src: require('../../assets/images/textbook.png'),
                        title: '教材',
                        index: 2,
                        mtype: 0,
                    },
                    {
                        class: 'micro-lesson',
                        icon: '&#xe688;',
                        src: require('../../assets/images/micro-lesson.png'),
                        title: '微课',
                        index: 3,
                        mtype: 0,
                    },
                    {
                        class: 'animation',
                        icon: '&#xe68e;',
                        src: require('../../assets/images/animation.png'),
                        title: '教学设计',
                        index: 4,
                        mtype: 0,
                    },
                    {
                        class: 'peitao',
                        icon: '&#xe68e;',
                        src: require('../../assets/images/peitao.png'),
                        title: '配套材料',
                        index: 5,
                        mtype: 0,
                    },
                    {
                        class: 'test-center',
                        icon: '&#xe678;',
                        src: require('../../assets/images/test-center.png'),
                        title: '测试中心',
                        index: 6,
                        mtype: 0,
                    },
                    {
                        class: 'training-center',
                        icon: '&#xe66f;',
                        src: require('../../assets/images/training-center.png'),
                        title: '实训中心',
                        index: 7,
                        mtype: 0,
                    },
                    {
                        class: 'person-resource',
                        icon: '&#xe65d;',
                        src: require('../../assets/images/person-resource.png'),
                        title: '个人资源',
                        index: 8,
                        mtype: 0,
                    },
                ],
            }
        },
        created() {
            this.getTypeList()
        },
        mounted() {
            this.selectClassify();
            this.getCourseTempList();
        },
        watch: {
            nodeIndex(d) {
                this.setNull();
            },
            chapterIndex(d) {
                this.setNull();
            },
            $route() {
                this.select_course_id = this.$route.query.course_id
            }
        },
        methods: {
            handleSizeChange(val) {
            },
            handleCurrentChange(val) {
                this.selectClassify(val);
            },
            setNull() {
                let arr = new Array(14).fill({});
                this.$emit('handleList', arr);
                this.courseInfo();
            },
            uploadFile() {
                this.dialogUploadMaterial = true;
            },
            //上传资料
            changeMaterial(event) {
                let fileList = event.target.files[0];
                this.uploadMaterialForm.file = fileList;
                this.uploadMaterialForm.fileName = fileList.name;
                let formData = new FormData();
                formData.append('file', this.uploadMaterialForm.file);
                this.$thttp.axiosPost(this.$api.meterial_uploadFile, formData, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg);
                        this.uploadMaterialForm.resource_file_type = res.data.file_type;
                        this.uploadMaterialForm.file_path = res.data.file_path.url;
                        if (res.data.file_type === 'doc') {
                            this.isUploadPDF = true;
                            this.isPDFInput = true;
                            return;
                        } else if (res.data.file_type === 'docx') {
                            this.isUploadPDF = true;
                            this.isPDFInput = true;
                            return;
                        } else if (res.data.file_type === 'xls') {
                            this.isUploadPDF = true;
                            this.isPDFInput = true;
                            return;
                        } else if (res.data.file_type === 'xlsx') {
                            this.isUploadPDF = true;
                            this.isPDFInput = true;
                            return;
                        } else if (res.data.file_type === 'ppt') {
                            this.isUploadPDF = true;
                            this.isPDFInput = true;
                            return;
                        } else if (res.data.file_type === 'pptx') {
                            this.isUploadPDF = true;
                            this.isPDFInput = true;
                            return;
                        } else {
                            this.isUploadPDF = false;
                            this.isPDFInput = false;

                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //上传PDF
            changeMaterialPDF(event) {
                let fileList = event.target.files[0];
                this.uploadMaterialForm.pdfFile = fileList;
                this.uploadMaterialForm.pdfName = fileList.name;
                let formData = new FormData();
                formData.append('file', this.uploadMaterialForm.pdfFile);
                this.$thttp.axiosPost(this.$api.meterial_uploadFile, formData, (res) => {
                    if (res.code === 200) {
                        if (res.data.file_type === 'pdf') {
                            this.isUploadPDF = false;
                            this.uploadMaterialForm.file_preview_path = res.data.file_path.url;
                        } else {
                            this.$message.error('请上传同文件pdf格式');
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //保存上传资料
            saveUploadMaterial() {
                let formData = new FormData();
                formData.append('course_id', this.select_course_id);
                formData.append('course_chapter', this.chapterIndex);
                formData.append('course_node', this.nodeIndex);
                formData.append('meterial_type_id', 8);
                formData.append('title', this.uploadMaterialForm.title);
                formData.append('file_path', this.uploadMaterialForm.file_path);
                formData.append('resource_file_type', this.uploadMaterialForm.resource_file_type);
                if (this.uploadMaterialForm.file_preview_path) {
                    formData.append('file_preview_path', this.uploadMaterialForm.file_preview_path);
                }
                this.$thttp.axiosPost(this.$api.meterial_saveMeterial, formData, (res) => {
                    if (res.code === 200) {
                        this.$message.success('上传成功');
                        this.resetUploadMaterialForm();
                        this.selectClassify()
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //重置上传资料表单
            resetUploadMaterialForm() {
                this.dialogUploadMaterial = false;
                this.uploadMaterialForm = {
                    select_resources_type: '',
                    title: '',
                    resource_file_type: '',
                    file: '',
                    fileName: '',
                    file_path: '',
                    pdfFile: '',
                    pdfName: '',
                    file_preview_path: '',
                }
                this.isPDFInput = false;
            },
            goBack() {
                this.$router.go(-1)
            },
            closeDialog() {
                this.src = '';
                this.dialogVisible = false
            },
            backBtn() {
                this.src = '';
                this.dialogVisible = false
            },
            downloadFile(item) {
                let src = item.file_path.url;
                let x = new XMLHttpRequest();
                x.open("GET", src, true);
                x.responseType = 'blob';
                x.onload = function (e) {
                    let url = window.URL.createObjectURL(x.response)
                    let a = document.createElement('a');
                    a.href = url
                    a.download = item.title
                    a.click()
                }
                x.send();
            },
            viewDetail(item) {
                this.src = item.file_path.src;
                this.dialogVisible = true;
            },
            getTypeList() {
                this.$thttp.axiosGet(this.$api.meterial_mtlTypeList, res => {
                    if (res.code == 200) {
                        this.typeList = res.data;
                        this.typeList.forEach(item => {
                            item.oldIndex = 8;
                            if (item.id == 1) {
                                item.icon = '&#xe733;';
                                item.mtype = 1;
                                item.title = item.name;
                                item.showId = 1;
                            }
                            if (item.id == 2) {
                                item.icon = '&#xeb62;';
                                item.mtype = 1;
                                item.showId = 2;
                                item.title = item.name;
                            }
                            if (item.id == 3) {
                                item.icon = '&#xeb61;';
                                item.mtype = 1;
                                item.showId = 3;
                                item.title = item.name;
                            }
                            if (item.id == 4) {
                                item.icon = '&#xeb63;';
                                item.mtype = 1;
                                item.showId = 4;
                                item.title = item.name;
                            }
                            if (item.id == 5) {
                                item.icon = '&#xe625;';
                                item.mtype = 1;
                                item.showId = 5;
                                item.title = item.name;
                            }
                            if (item.id == 8) {
                                item.icon = '&#xe65e;';
                                item.mtype = 1;
                                item.showId = 8;
                                item.title = item.name;
                            }
                        })
                        //有时typeList请求较慢，比对数据时会缺失，所以比对放到取完数据后执行
                        this.courseInfo();
                    }
                })
            },
            selectClassify(val) {
                this.selectAppList = [];
                let param = {
                    course_id: this.$route.query.course_id,
                    course_chapter: this.$route.query.zhangIndex,
                    course_node: this.$route.query.jieIndex,
                    page: val ? val : this.currentPage,
                    limit: 6
                }
                this.$thttp.axiosGetBy(this.$api.meterial_showMeterialList, param, res => {
                    if (res.code === 200) {
                        this.resourceData = res.data.data;
                        this.total = res.data.total;
                        this.resourceData.forEach(item => {
                            this.applicationIdList.push(item.id)
                            item.ctype = 6;
                        })
                    }
                })
            },
            selectApp(val) {
                let checkedCount = val.length;
                this.checkAll = checkedCount === this.resourceData.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.resourceData.length;
            },
            //全选
            handleCheckAllChange(val) {
                this.isIndeterminate = false;
                this.selectAppList = val ? this.applicationIdList : [];
            },
            //删除
            delAppBtn(item) {
                this.$confirm('删除后不能恢复，确定是否删除？', '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let id = this.selectAppList.join(',')
                    this.$thttp.axiosGetBy(this.$api.delMeterial, {id: item.id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg);
                            this.selectClassify();
                            this.selectAppList = [];
                        } else {
                            this.$message.error(res.msg);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            },
            onMove(evt) {
                let obj = evt.draggedContext.element;
                let arr = JSON.parse(JSON.stringify(this.select_list))
                for (let i = 0; i < arr.length; i++) {
                    if (JSON.stringify(arr[i]) === JSON.stringify(obj)) {
                        return false;
                    }
                }
                if (arr.includes(obj)) {
                    return false;
                }
            },
            onEnd(evt) {
                let oldIndex = evt.oldIndex;
                let newIndex = evt.newIndex;
                if (this.select_list.length > 14) {
                    this.select_list.splice(newIndex + 1, 1)
                }
                this.module_list = this.select_list;
                this.$emit('handleList', this.select_list)
            },
            saveCourseCustom() {
                // this.$emit('handleClick', true)
                let list = [];
                if (this.module_list.length) {
                    this.module_list.forEach((item, index) => {
                        if (JSON.stringify(item) !== '{}') {
                            if (item.ctype && item.id) {
                                let obj = {
                                    type: item.ctype,
                                    id: item.id,
                                    index: index,
                                }
                                if (item.icon) {
                                    obj.oldIndex = item.index;
                                }
                                list.push(obj)
                            } else {
                                let formatList = this.formatResources(item.index);
                                // console.log(1064,formatList)
                                if (formatList.length) {
                                    formatList.forEach((item2, index2) => {
                                        let obj2 = {
                                            type: item2.ctype,
                                            id: item2.id,
                                            index: index,
                                        }
                                        if (item.icon) {
                                            obj2.oldIndex = item.index;
                                        }
                                        list.push(obj2)
                                    })
                                }
                            }
                        }
                    })
                }
                // console.log(1025,list);
                this.selectTemplateList = list;
                if (this.selectTemplateList.length) {
                    this.saveTemplateShow = true;
                } else {
                    this.$message.warning('请选择至少一个模板！')
                }
            },
            formatResources(type) {
                let reslist = [];
                let reslist1 = [];
                //格式化资源的属性名，这边是查出所有的资源
                _.each(this.resourceList, (item) => {
                    let tmp = {}
                    if (Number(item.resource_type_id) === Number(type)) {
                        tmp = item;
                        tmp.id = item.resource_id;         //资源id
                        tmp.title = item.resource_title;      // 资源名称
                        tmp.ctype = 3;                         //资源位于课程内容中的标识：系统资源 => 3; 系统测试(小测) => 4；项目（实训） => 5；配套材料（教师和学校管理员自己上传）=> 6
                        tmp.oldIndex = type;
                        tmp.type = 3;
                        reslist.push(tmp);
                    }
                });
                //遍历出当前章节的资源
                _.each(this.courseContent, (item) => {
                    _.each(reslist, (item1) => {
                        if (Number(item.id) === Number(item1.id)) {
                            reslist1.push(item1);
                        }
                    })
                })
                return reslist1;
            },
            useTempClick() {
                this.$emit('handleClick', true)
            },
            delModule(item, index) {
                this.select_list[index] = {};
                this.module_list = this.select_list;
                this.$emit('handleList', this.select_list);
                this.$forceUpdate();
            },
            closeTemplateBtn() {
                this.saveTemplateShow = false;
                this.templateName = ''
            },
            saveTemplateBtn() {
                if (this.templateName.length < 3 || this.templateName.length > 10) {
                    this.$message.warning('名称长度为3-10个字！');
                    return
                }
                let course_content = JSON.stringify(this.selectTemplateList)
                let param = {
                    course_id: this.select_course_id,
                    course_chapter: this.chapterIndex,
                    course_node: this.nodeIndex,
                    name: this.templateName,
                    course_content: course_content
                }
                this.$thttp.axiosPost(this.$api.course_saveCourseTemp, param, res => {
                    // console.log(1078,res)
                    if (res.code === 200) {
                        this.$message({
                            message: '保存成功！',
                            type: 'success',
                            duration: 1000,
                            onClose: () => {
                                this.getCourseTempList();
                                this.saveTemplateShow = false
                            }
                        })
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            selectTempClick(item) {
                // console.log(1127,item)
                let course_content = JSON.parse(item.course_content);
                this.handleTempCustom(course_content)
                // return
                // this.$refs.popoverRef.doClose()
            },
            handleTempCustom(val) {
                // console.log(1015,this.module_list )
                let arr = new Array(14).fill({});
                //查询到的自定义排版
                val.forEach(item => {
                    //oldIndex是为了分辨单独拖拽进去的
                    if (item.oldIndex) {
                        //1到5是上面一行的精选课程
                        if (item.type < 6) {
                            arr[item.index] = this.showCourseList[item.oldIndex - 1]
                        } else if (item.type == 6) {//6是配套材料
                            arr[item.index] = this.typeList[item.oldIndex - 1];
                        }
                    } else {
                        if (item.type == 3) {
                            // console.log(this.resourceList,123)
                            this.resourceList.forEach(item1 => {
                                if (item1.resource_id == item.id) {
                                    let obj = this.resourceList[this.resourceList.indexOf(item1)];
                                    obj.ctype = item.type;
                                    obj.id = item1.resource_id;
                                    obj.type = item1.resource_type_id;
                                    arr[item.index] = obj;
                                }
                            })
                        } else if (item.type == 4) {
                            this.papersList.forEach(item2 => {
                                if (item2.id == item.id) {
                                    let obj = this.papersList[this.papersList.indexOf(item2)];
                                    obj.ctype = item.type;
                                    obj.type = 6;
                                    arr[item.index] = obj;
                                }
                            })
                        } else if (item.type == 5) {
                            this.projectsList.forEach(item2 => {
                                if (item2.id == item.id) {
                                    let obj = this.projectsList[this.projectsList.indexOf(item2)];
                                    obj.ctype = item.type;
                                    obj.type = 7;
                                    arr[item.index] = obj;
                                }
                            })
                        } else if (item.type == 6) {
                            this.material_list.forEach(item2 => {
                                if (item2.id == item.id) {
                                    let obj = this.material_list[this.material_list.indexOf(item2)];
                                    obj.ctype = item.type;
                                    arr[item.index] = obj;
                                }
                            })
                        }
                    }
                })
                this.$emit('handleList', arr);
            },
            //删除模板
            deleTemp(id) {
                this.$confirm('此操作将永久删除该模板, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$thttp.axiosGetBy(this.$api.course_delCourseTemp, {id: id}, res => {
                        // console.log(res)
                        if (res.code === 200) {
                            this.$message({
                                message: '删除成功！',
                                type: 'success',
                                duration: 1000,
                                onClose: () => {
                                    this.getCourseTempList();
                                }
                            })
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            courseInfo() {
                //
                Promise.all([
                    this.courseInfoByPromise(this.select_course_id),
                    this.courseInfoCustom(this.select_course_id, this.chapterIndex, this.nodeIndex),
                ]).then(([res, custom]) => {
                    // console.log('课程内容', res);
                    this.courseName = res.course_name;
                    this.resourceList = res.reslist;
                    this.papersList = res.papers;
                    this.projectsList = res.projects;
                    this.courseContent = res.course_content[this.chapterIndex].children[this.nodeIndex].children;
                    // console.log('课程的平台资源', this.resourceList);
                    //整理资源
                    //课件
                    this.cKejian = this.formatResources(1);
                    // console.log('1222', this.cKejian);
                    //教材
                    this.cJiaocai = this.formatResources(2);
                    // console.log('2', this.cJiaocai);
                    //微课
                    this.cWeike = this.formatResources(3);
                    // console.log('3', this.cWeike);
                    //动画
                    this.cDonghua = this.formatResources(4);
                    // console.log('4', this.cDonghua);
                    //配套材料
                    this.cPeitao = this.formatResources(5);
                    this.handleShowPeiTao();
                    // console.log('5', this.cPeitao);
                    //教案
                    this.cJiaoan = this.formatResources(6);
                    // console.log('6', this.cJiaoan);
                    //测试中心
                    this.testCenter = this.formatExams(res.papers);
                    // console.log('小测', this.testCenter);
                    //实训中心
                    this.practiceCenter = this.formatProject(res.projects);
                    // console.log('实训', this.practiceCenter);
                    this.cZhishidian = this.formatMaterial(1, 1)//知识点
                    this.cSucaiku = this.formatMaterial(2, 2)//素材库
                    this.cAnliku = this.formatMaterial(3, 3)//案例库
                    this.cPeiWeike = this.formatMaterial(4, 4)//微课
                    this.cXiangguanwenzhang = this.formatMaterial(5, 5)//相关文章
                    this.cPersonResource = this.formatMaterial(8, 6)//个人资源
                    let obj = {};
                    obj.cKejian = this.cKejian;
                    obj.cJiaocai = this.cJiaocai;
                    obj.cWeike = this.cWeike;
                    obj.cDonghua = this.cDonghua;
                    obj.cPeitao = this.cPeitao;
                    obj.cJiaoan = this.cJiaoan;
                    obj.testCenter = this.testCenter;
                    obj.practiceCenter = this.practiceCenter;
                    obj.cZhishidian = this.cZhishidian;
                    obj.cSucaiku = this.cSucaiku;
                    obj.cAnliku = this.cAnliku;
                    obj.cPeiWeike = this.cPeiWeike;
                    obj.cXiangguanwenzhang = this.cXiangguanwenzhang;
                    obj.cPersonResource = this.cPersonResource;
                    this.$emit('savaData', obj);
                    this.course_content = custom.course_content;
                    //如果有自定义排版，按自定义排版来
                    // console.log(616,this.course_content)
                    if (this.course_content && this.course_content.length > 0) {
                        this.handleCourseCustom(custom);
                    } else {//默认是平铺所有资源，何耀明说的
                        this.fillAll();
                    }
                }).catch(err => {
                    this.$common.axiosErrorMsg(err);
                });
            },
            handleCourseCustom(val) {
                let arr = this.select_list;
                //查询到的自定义排版
                this.$emit('savaId', val.id)
                this.course_content.forEach(item => {
                    //oldIndex是为了分辨单独拖拽进去的
                    if (item.oldIndex) {
                        //1到5是上面一行的精选课程
                        if (item.type < 6) {
                            arr[item.index] = this.showCourseList[item.oldIndex - 1]
                        } else if (item.type == 6) {//6是配套材料
                            arr[item.index] = this.typeList[item.oldIndex - 1];
                        }
                    } else {
                        if (item.type == 3) {
                            this.resourceList.forEach(item1 => {
                                if (item1.resource_id == item.id) {
                                    let obj = this.resourceList[this.resourceList.indexOf(item1)];
                                    obj.ctype = item.type;
                                    obj.id = item1.resource_id;
                                    obj.type = item1.resource_type_id;
                                    arr[item.index] = obj;
                                }
                            })
                        } else if (item.type == 4) {
                            this.papersList.forEach(item2 => {
                                if (item2.id == item.id) {
                                    let obj = this.papersList[this.papersList.indexOf(item2)];
                                    obj.ctype = item.type;
                                    obj.type = 6;
                                    arr[item.index] = obj;
                                }
                            })
                        } else if (item.type == 5) {
                            this.projectsList.forEach(item2 => {
                                if (item2.id == item.id) {
                                    let obj = this.projectsList[this.projectsList.indexOf(item2)];
                                    obj.ctype = item.type;
                                    obj.type = 7;
                                    arr[item.index] = obj;
                                }
                            })
                        } else if (item.type == 6) {
                            this.material_list.forEach(item2 => {
                                if (item2.id == item.id) {
                                    let obj = this.material_list[this.material_list.indexOf(item2)];
                                    obj.ctype = item.type;
                                    arr[item.index] = obj;
                                }
                            })
                        }
                    }
                })
                this.$emit('handleList', arr);
            },
            courseInfoByPromise(id) {
                return new Promise((resolve, reject) => {
                    let param = {
                        id
                    }
                    this.$thttp.axiosGetBy(this.$api.courseinfo, param, (res) => {
                        if (res.code === 200) {
                            resolve(res.data);
                        } else {
                            this.$message({
                                type: 'warning',
                                message: res.msg,
                                duration: 1000
                            });
                            resolve(false);
                        }
                    }, (err) => {
                        reject(err);
                    })
                });
            },
            courseInfoCustom(course_id, chapteIndex, nodeIndex) {
                return new Promise((resolve, reject) => {
                    let param = {
                        course_id: course_id,
                        course_chapter: chapteIndex,
                        course_node: nodeIndex
                    }
                    this.$thttp.axiosGetBy(this.$api.course_getcoursecustom, param, (res) => {
                        if (res.code === 200) {
                            resolve(res.data);
                        } else {
                            this.$message({
                                type: 'warning',
                                message: res.msg,
                                duration: 1000
                            });
                            resolve(false);
                        }
                    }, (err) => {
                        reject(err);
                    })
                })
            },
            handleShowPeiTao() {
                let arr = [];
                let len = this.cPeitao.length;
                if (len > 0 && len < 10) {
                    for (let i = 0; i < len; i++) {
                        arr.push(this.cPeitao[i]);
                    }
                } else if (len > 10) {
                    for (let j = 0; j < 10; j++) {
                        arr.push(this.cPeitao[j]);
                    }
                }
                this.showPeitao = arr;
            },
            //后期可优化，暂时这么写
            fillAll() {
                // console.log(766,this.showCourseList)
                let arr = [];
                this.cKejian.forEach(item => {
                    let tmp = {};
                    if (item) {
                        if (this.cKejian.length > 1) {
                            tmp = item;
                            tmp.type = 1;
                            // delete tmp.oldIndex;
                        } else {
                            tmp = this.showCourseList[0];
                            tmp.oldIndex = 1;
                        }
                        arr.push(tmp)
                    }
                });
                this.cJiaocai.forEach(item => {
                    let tmp = {};
                    if (item) {
                        if (this.cJiaocai.length > 1) {
                            tmp = item;
                            tmp.type = 2;
                            // delete tmp.oldIndex;
                        } else {
                            tmp = this.showCourseList[1];
                            tmp.oldIndex = 2;
                        }
                        arr.push(tmp)
                    }
                });
                this.cWeike.forEach(item => {
                    let tmp = {};
                    if (item) {
                        if (this.cWeike.length > 1) {
                            tmp = item;
                            tmp.type = 3;
                            //delete tmp.oldIndex;
                        } else {
                            tmp = this.showCourseList[2];
                            tmp.oldIndex = 3;
                        }
                        arr.push(tmp)
                    }
                });
                this.cDonghua.forEach(item => {
                    let tmp = {};
                    if (item) {
                        if (this.cDonghua.length > 1) {
                            tmp = item;
                            tmp.type = 4;
                            //delete tmp.oldIndex;
                        } else {
                            tmp = this.showCourseList[3];
                            tmp.oldIndex = 4;
                        }
                        arr.push(tmp)
                    }
                });
                this.cPeitao.forEach(item => {
                    let tmp = {};
                    if (item) {
                        if (this.cPeitao.length > 1) {
                            tmp = item;
                            tmp.type = 5;
                            //delete tmp.oldIndex;
                        } else {
                            tmp = this.showCourseList[4];
                            tmp.oldIndex = 5;
                        }
                        arr.push(tmp)
                    }
                });
                this.testCenter.forEach(item => {
                    let tmp = {};
                    if (item) {
                        if (this.testCenter.length > 1) {
                            tmp = item;
                            tmp.type = 6;
                            //delete tmp.oldIndex;
                        } else {
                            tmp = this.showCourseList[5];
                            tmp.oldIndex = 6;
                        }
                        arr.push(tmp)
                    }
                });
                this.practiceCenter.forEach(item => {
                    let tmp = {};
                    if (item) {
                        if (this.practiceCenter.length > 1) {
                            tmp = item;
                            tmp.type = 7;
                            //delete tmp.oldIndex;
                        } else {
                            tmp = this.showCourseList[6];
                            tmp.oldIndex = 7;
                        }
                        arr.push(tmp)
                    }
                });
                this.cZhishidian.forEach(item => {
                    let tmp = {};
                    if (item) {
                        if (this.cZhishidian.length > 1) {
                            tmp = item;
                            delete tmp.oldIndex
                        } else {
                            tmp = this.typeList[0];
                        }
                        arr.push(tmp)
                    }
                });
                this.cSucaiku.forEach(item => {
                    let tmp = {};
                    if (item) {
                        if (this.cSucaiku.length > 1) {
                            tmp = item;
                            delete tmp.oldIndex
                        } else {
                            tmp = this.typeList[1];
                        }
                        arr.push(tmp)
                    }
                });
                this.cAnliku.forEach(item => {
                    let tmp = {};
                    if (item) {
                        if (this.cAnliku.length > 1) {
                            tmp = item;
                            delete tmp.oldIndex
                        } else {
                            tmp = this.typeList[2];
                        }
                        arr.push(tmp)
                    }
                });
                this.cPeiWeike.forEach(item => {
                    let tmp = {};
                    if (item) {
                        if (this.cPeiWeike.length > 1) {
                            tmp = item;
                            delete tmp.oldIndex
                        } else {
                            tmp = this.typeList[3];
                        }
                        arr.push(tmp)
                    }
                });
                this.cXiangguanwenzhang.forEach(item => {
                    let tmp = {};
                    if (item) {
                        if (this.cXiangguanwenzhang.length > 1) {
                            tmp = item;
                            delete tmp.oldIndex
                        } else {
                            tmp = this.typeList[4];
                        }
                        arr.push(tmp)
                    }
                });
                this.cPersonResource.forEach(item => {
                    let tmp = {};
                    if (item) {
                        if (this.cPersonResource.length > 1) {
                            tmp = item;
                            delete tmp.oldIndex
                        } else {
                            tmp = this.typeList[5];
                        }
                        arr.push(tmp)
                    }
                });
                let len = arr.length;
                //因为我只画了14个格子
                if (len > 14) {
                    arr.splice(14, len - 14)
                } else {
                    let newArr = new Array(14 - len).fill({})
                    arr.push.apply(arr, newArr)
                }
                this.$emit('handleList', arr)
            },
            formatExams(exams) {
                let reslist = [];
                let reslist1 = [];
                _.each(exams, (item) => {
                    let tmp = {}
                    tmp = item;
                    tmp.title = item.paper_name;
                    tmp.ctype = 4;
                    tmp.oldIndex = 6;
                    reslist.push(tmp)
                });
                //遍历出当前章节的资源
                _.each(this.courseContent, (item) => {
                    _.each(reslist, (item1) => {
                        if (Number(item.id) === Number(item1.id)) {
                            reslist1.push(item1);
                        }
                    })
                })
                return reslist1;
            },
            formatProject(projects) {
                let reslist = [];
                let reslist1 = [];
                _.each(projects, (item) => {
                    let tmp = {};
                    tmp = item;
                    tmp.id = item.id;
                    tmp.ctype = 5;
                    tmp.title = item.project_title;
                    tmp.oldIndex = 7;
                    reslist.push(tmp);
                });
                //遍历出当前章节的资源
                _.each(this.courseContent, (item) => {
                    _.each(reslist, (item1) => {
                        if (Number(item.id) === Number(item1.id)) {
                            reslist1.push(item1);
                        }
                    })
                })
                return reslist1;
            },
            formatMaterial(type, index) {
                let reslist = [];
                //格式化资源的属性名
                _.each(this.material_list, (item) => {
                    let tmp = {}
                    if (Number(item.meterial_type_id) === Number(type)) {
                        tmp = item;
                        tmp.id = item.id;         //资源id
                        tmp.ctype = 6;                         //资源位于课程内容中的标识：系统资源 => 3; 系统测试(小测) => 4；项目（实训） => 5；配套材料（教师和学校管理员自己上传）=> 6
                        tmp.oldIndex = index;
                        reslist.push(tmp);
                    }
                });
                return reslist;
            },
            getCourseTempList() {
                let param = {
                    course_id: this.select_course_id,
                    course_chapter: this.chapterIndex,
                    course_node: this.nodeIndex
                }
                this.$thttp.axiosGetBy(this.$api.course_getCourseTemp, param, res => {
                    // console.log(1137,res)
                    if (res.code === 200) {
                        this.courseTempList = res.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .upload-resources-dialog {

        ::v-deep .el-dialog__header {
            background: #409EFF;

            .el-dialog__title {
                color: #ffffff;
            }

            .el-dialog__headerbtn .el-dialog__close {
                color: #ffffff;
            }
        }

        .info-form {
            .file {
                ::v-deep .el-form-item__content {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                }

                .file-name {
                    display: flex;
                    position: relative;
                }

                .upload-cover {
                    width: 100px;
                    height: 40px;
                    background: #409EFF;
                    border-radius: 4px;
                    color: #ffffff;
                    margin-right: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i.iconfont {
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }

                .cover-input {
                    position: absolute;
                    top: 0;
                    width: 100px;
                    height: 30px;
                    opacity: 0;
                    cursor: pointer;
                }
            }

            .resources-class-cascader {
                width: 100%;
            }

            .select-course {
                width: 100%;
            }
        }
    }

    .course-ware-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background: #fff;


        .resource-classify {
            display: flex;
            align-items: center;
            padding: 26px 30px;
            position: relative;

            .head-title {
                font-size: 18px;
            }

            .back-btn {
                position: absolute;
                right: 30px;
            }

            span {
                display: inline-block;
                line-height: 1;
                font-size: 14px;
                padding: 8px 12px;
                border-radius: 4px;
                font-weight: 400;
                cursor: pointer;
            }

            .class-item {
                background: #ECF5FF;
                border: 1px solid #DCECFE;
                color: #409EFF;
            }

            &:after {
                content: '';
                position: absolute;
                left: 36px;
                right: 33px;
                bottom: 0;
                height: 1px;
                background: #e9e9e9;
            }
        }

        .administration {
            /*display: flex;*/
            .administration-box {
                padding: 0 30px;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .admin-btn {
                    padding: 8px 28px;
                }
            }
        }

        .course-ware-list {
            flex: 1;
            width: 100%;
            height: 1%;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            ::v-deep .el-scrollbar__view {
                height: 100%;
            }

            .course-ware {
                padding: 0px 30px;
                display: flex;
                flex-wrap: wrap;
                min-height: 90%;

                .dataTabble {
                    width: 100%;
                    margin-top: 18px;
                    border-bottom: 1px solid #DDDDDD;

                    .table-head {
                        width: 100%;
                        height: 50px;
                        background: #E6F0FE;;

                        .table-head-title {
                            height: 50px;
                            font-size: 16px;

                            th {
                                color: #333333;
                                font-weight: 400;
                                width: 25%;
                            }

                            .th-title1 {
                                text-align: left;
                                padding-left: 40px;
                            }
                        }
                    }

                    .table-body {
                        height: 100%;
                        border-bottom: 1px solid #DDDDDD;

                        .table-trBox {
                            height: 100px;
                            position: relative;

                            td {
                                text-align: center;
                            }

                            .td-title {
                                text-align: left;
                                padding-left: 40px;
                            }

                            .opt-box {
                                height: 100px;

                                a {
                                    display: flex;
                                    align-items: center;
                                    width: 33%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .page-box {
            margin-top: 20px;
            text-align: center;
            margin-bottom: 20px;
        }

        .module-content {
            display: flex;
            justify-content: space-between;
            padding: 19px 20px;
            /*position: absolute;*/
            /*bottom: 20px;*/
            /*left: 0;*/
            /*right: 0;*/
            align-items: center;
            box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.18);
            position: relative;

            .module {
                flex: 1;
                width: 1%;

                ::v-deep .el-scrollbar__view {
                    white-space: nowrap;
                }
            }

            .module-list {
                padding: 6px;
                display: flex;
                position: relative;

                .new-module-item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    border-radius: 10px;
                    margin-right: 15px;
                }

                .module-item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    border: 1px dashed #CCCCCC;
                    border-radius: 24px;
                    margin-right: 13px;
                }

                .item-cover {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border-radius: 50%;

                    i {
                        font-size: 20px;
                        color: #fff;
                    }
                }

                .item-cover1 {
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    border-radius: 50%;

                    i {
                        font-size: 20px;
                        color: #fff;
                    }

                    .show-img {
                        width: 100%;
                        height: 100%;
                        box-shadow: 0px 2px 9px 1px rgba(87, 77, 237, 0.17);
                        border-radius: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .img-box {
                            width: 50px;
                            height: 50px;
                            border-radius: 24px;
                        }
                    }
                }

                .show1 {
                    background: #61BCEB;
                }

                .show2 {
                    background: #3DCAC8;
                }

                .show3 {
                    background: #F0BC49;
                }

                .show4 {
                    background: #EC75A4;
                }

                .show5 {
                    background: #79ACDC;
                }

                .show6 {
                    background: #61BCEB;
                }

                .show7 {
                    background: #5AB9E9;
                }

                .courseware {
                    background: linear-gradient(0deg, #F5D971, #EDA92E);
                }

                .textbook {
                    background: linear-gradient(0deg, #7BCBF2, #44ACE3);
                }

                .lesson-plan {
                    background: linear-gradient(0deg, #53DED1, #2BB9BF);
                }

                .micro-lesson {
                    background: linear-gradient(0deg, #EE89B5, #EA5D90);
                }

                .animation {
                    background: linear-gradient(0deg, #F39E72, #EC6C6C);
                }

                .test-center {
                    background: linear-gradient(0deg, #8CC5EC, #5F8AC7);
                }

                .training-center {
                    background: linear-gradient(0deg, #ED83AA, #EC6B6F);
                }

                .knowledge-points {
                    background: linear-gradient(0deg, #77C9F1, #47AEE4);
                }

                .material-library {
                    background: linear-gradient(0deg, #51DCD0, #2CBAC0);
                }

                .case-library {
                    background: linear-gradient(0deg, #F5D971, #EDA92E);
                }

                .related-copywriting {
                    background: linear-gradient(0deg, #8BC3EB, #628EC9);
                }

                .personal-info {
                    background: linear-gradient(0deg, #51DCD0, #2FBDC1);
                }

                .del-btn {
                    width: 18px;
                    height: 18px;
                    background: #FF0000;
                    color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    opacity: 0;
                    cursor: pointer;
                    z-index: 1;
                }

                .show-title {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    z-index: 1;
                }

                .new-module-item:before {
                    content: "";
                    width: 0;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    opacity: 0;
                }


                .new-module-item:hover:before {
                    width: 100%;
                    left: 0;
                    opacity: 0.5;
                }

                .new-module-item:hover .del-btn {
                    opacity: 1;
                }
            }

            .module-save-btn {
                width: 60px;
                height: 60px;
                background: #409EFF;
                border-radius: 10px;
                color: #FFFFFF;
                font-size: 14px;
                //line-height: 60px;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                font-weight: 400;
                margin-right: 20px;

                &:hover {
                    cursor: pointer;
                }
            }

            .adhibition-btn {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-right: 20px;
                width: 60px;
                height: 60px;
                background: #409EFF;
                border-radius: 10px;
                color: #FFFFFF;
                font-size: 14px;
                font-weight: 400;
                cursor: pointer;
            }
        }
    }

    .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 50px;
    }

    ::v-deep .saveTemplate {
        .el-dialog__header {
            background: #409EFF;

            span {
                color: #fff;
                font-size: 16px;
            }

            .el-icon-close {
                color: #fff;
            }
        }

        .el-dialog__body {
            padding: 70px 0px 20px 50px;
        }

        .template-box {
            display: flex;
            flex-direction: column;

            .template-box-name {
                display: flex;
                align-items: center;
                color: #333333;
                font-size: 14px;
                padding-right: 60px;
            }

            .template-box-btn {
                margin-top: 70px;
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;

                .el-button {
                    margin-left: 20px;
                }
            }
        }
    }

    .select-positioning {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        box-shadow: 0px 1px 6px 1px rgba(64, 158, 255, 0.2);
        border-radius: 10px;
        //position: absolute;
        //right: -20px;
        //top: -78px;
        //z-index: 99;
        background: #409EFF;
        i {
            font-size: 29px;
            color: #9CA9C3;
        }

        .hover-two {
          background: #409EFF;
          color: #FFFFFF;
          font-size: 14px;
          //line-height: 60px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          font-weight: 400;
          cursor: pointer;
          align-items: center;
          width: 100%;
          height: 100%;
        }
    }

    .temp-popover {
        display: flex;
        flex-direction: column;

        .popover-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            height: 30px;

            &:hover {
                background: #ECF5FF;

                span {
                    color: #409EFF;
                }
            }

            span {
                color: #666666;
                font-size: 14px;
                padding-left: 10px;
            }

            i {
                color: #666666;
                font-size: 12px;
                padding-right: 10px;
            }
        }
    }
</style>