<template>
    <teacherMatingDetailModule :select_list="select_list" :savaData="savaData" :module_list="module_list" @handleClick="handleClick" :courseInfos="courseInfos" :courseTempList="courseTempList"
                               :searchInput='searchInput' @handleList="handleList" :chapterIndex="chapterIndex" :nodeIndex="nodeIndex" :updateTempList="updateTempList"/>
</template>

<script>
    import teacherMatingDetailModule from './../../components/teacherCourse/teacherMatingDetailModule.vue'

    export default {
        name: "teacherMatingDetail",
        props: ['courseInfos', 'nodeIndex', 'chapterIndex', 'select_list', 'module_list', 'searchInput', 'courseTempList'],
        components: {
            teacherMatingDetailModule
        },
        methods: {
            handleClick(val) {
                this.$emit('handleSave', true)
            },
            handleList(val) {
                this.$emit('handleList', val)
            },
            savaId(val){
                this.$emit('savaId',val)
           },
            updateTempList(){
              this.$emit('updateTempList');
            },
          savaData(obj){
              this.$emit('savaData',obj)
          }
        }
    }
</script>

<style scoped>

</style>